import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ElementsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Imágenes de Jardiners Monal"
        keywords={['jardin', 'presupuesto', 'jardinería', 'césped', 'diseño', 'barcelona', 'riego', 'terraza', 'rocalla', 'mantenimiento', 'plantas', 'árboles', 'piscina', 'pérgola']}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">

          <h2 id="images">Imágenes</h2>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.pic1.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>-</figcaption>
          </figure>
          <figure className="kg-card kg-image-card kg-width-wide">
            <Img
              fluid={data.pic2.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>-</figcaption>
          </figure>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.pic3.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>-</figcaption>
          </figure>
          <figure className="kg-card kg-image-card kg-width-wide">
            <Img
              fluid={data.pic4.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>-</figcaption>
          </figure>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.pic5.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>-</figcaption>
          </figure>
          <figure className="kg-card kg-image-card kg-width-wide">
            <Img
              fluid={data.pic6.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>-</figcaption>
          </figure>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.pic7.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>-</figcaption>
          </figure>
          <figure className="kg-card kg-image-card kg-width-wide">
            <Img
              fluid={data.pic8.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>-</figcaption>
          </figure>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.pic9.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>-</figcaption>
          </figure>
          <figure className="kg-card kg-image-card kg-width-wide">
            <Img
              fluid={data.pic10.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>-</figcaption>
          </figure>

        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    pic1: file(
      relativePath: { eq: "pexels-andre-moura-2635817.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pic2: file(
      relativePath: { eq: "pexels-couleur-2301188.jpg" }
      ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pic3: file(
      relativePath: { eq: "pexels-fox-212324.jpg" }
      ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pic4: file(
      relativePath: { eq: "pexels-joe-arts-3505000.jpg" }
      ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pic5: file(
      relativePath: { eq: "pexels-kristina-paukshtite-1444620.jpg" }
      ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pic6: file(
      relativePath: { eq: "pexels-laura-stanley-2252308.jpg" }
      ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pic7: file(
      relativePath: { eq: "pexels-lisa-2215534.jpg" }
      ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pic8: file(
      relativePath: { eq: "pexels-nothing-ahead-3571551.jpg" }
      ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pic9: file(
      relativePath: { eq: "pexels-olenka-sergienko-3541443.jpg" }
      ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    pic10: file(
      relativePath: { eq: "pexels-pixabay-432786.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ElementsPage location={props.location} data={data} {...props} />
    )}
  />
)
